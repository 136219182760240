import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import AnimationContainer from 'components/animation-container';

class ContactCreative extends React.Component {
	render() {
		const Section = styled.section`
			position: relative;
			overflow: hidden;
			background-color: #000;
			padding: 100px 0;
		`;

		const IconRow = styled(Row)`
			margin-top: 150px;
		`;

		const IconCol = styled(Col)`
			@media (max-width: 500px) {
				margin-bottom: 140px;
			}
		`;

		const IconContainer = styled.div`
			width: 150px;
			height: 150px;
			margin: auto;
			padding: 35px;
			text-align: center;
			position: relative;
			bottom: 75px;
			background-color: #04e5e5;
			border-radius: 150px;
			transition: 0.5s;
		`;

		const InfoPart = styled.div`
			min-height: 250px;
			background-color: #111;
			border: 2px solid #444;
			&:hover ${IconContainer} {
				background-color: #009e9e;
			}
		`;
		const Icon = styled.img`
			height: 70px;
			width: 70px;
			object-fit: contain;
		`;

		const InfoTitle = styled.h4`
			font-size: 35px;
			color: #fff;
			font-family: Teko;
			text-align: center;
		`;

		const Info = styled.div`
			position: relative;
			bottom: 30px;
		`;

		const InfoLinkContainer = styled.div`
			text-align: center;
		`;

		const InfoLink = styled.a`
			color: #04e5e5;
			transition: 0.5s;
			&:hover {
				color: #fff;
				text-decoration: none;
			}
		`;

		return (
			<Section id='contact'>
				<Container>
					<IconRow>
						<IconCol md={4}>
							<AnimationContainer animation='fadeIn' delay={500}>
								<InfoPart>
									<IconContainer>
										<Icon
											src={this.props.emailIcon.childImageSharp.fluid.src}
											alt='email'
										/>
									</IconContainer>
									<Info>
										<InfoTitle>Email</InfoTitle>
										<InfoLinkContainer>
											<InfoLink href='mailto:mattk.development@gmail.com'>
												mattk.development@gmail.com
											</InfoLink>
										</InfoLinkContainer>
									</Info>
								</InfoPart>
							</AnimationContainer>
						</IconCol>
						<IconCol md={4}>
							<AnimationContainer animation='fadeIn' delay={1000}>
								<InfoPart>
									<IconContainer>
										<Icon
											src={this.props.phoneIcon.childImageSharp.fluid.src}
											alt='phone'
										/>
									</IconContainer>
									<Info>
										<InfoTitle>Phone</InfoTitle>
										<InfoLinkContainer>
											<InfoLink href='tel:+(608)473-4723'>
												(608)473-4723
											</InfoLink>
										</InfoLinkContainer>
									</Info>
								</InfoPart>
							</AnimationContainer>
						</IconCol>
						<IconCol md={4}>
							<AnimationContainer animation='fadeIn' delay={1500}>
								<InfoPart>
									<IconContainer>
										<Icon
											src={this.props.mapIcon.childImageSharp.fluid.src}
											alt='map'
										/>
									</IconContainer>
									<Info>
										<InfoTitle>Address</InfoTitle>
										<InfoLinkContainer>
											<InfoLink
												target='_blank'
												href='https://www.google.com/maps/place/Wisconsin/@44.8827158,-91.8121182,7z/data=!3m1!4b1!4m5!3m4!1s0x52a8f40f9384e3af:0xf2d5d5b8f88649d6!8m2!3d43.7844397!4d-88.7878678'>
												Baraboo Wisconsin
											</InfoLink>
										</InfoLinkContainer>
									</Info>
								</InfoPart>
							</AnimationContainer>
						</IconCol>
					</IconRow>
				</Container>
			</Section>
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query {
				emailIcon: file(relativePath: { eq: "icons/email2.png" }) {
					childImageSharp {
						fluid(maxWidth: 500) {
							src
						}
					}
				}
				mapIcon: file(relativePath: { eq: "icons/map.png" }) {
					childImageSharp {
						fluid(maxWidth: 500) {
							src
						}
					}
				}
				phoneIcon: file(relativePath: { eq: "icons/phone.png" }) {
					childImageSharp {
						fluid(maxWidth: 500) {
							src
						}
					}
				}
			}
		`}
		render={({ emailIcon, mapIcon, phoneIcon }) => (
			<ContactCreative
				emailIcon={emailIcon}
				mapIcon={mapIcon}
				phoneIcon={phoneIcon}
				{...props}
			/>
		)}
	/>
);
